import "./App.css";

import Form from "./Form/Form";

function App() {
    return (
        <div>
            <Form />
        </div>
    );
}

export default App;
