import {
    Autocomplete,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./form.css";
import axios from "axios";
import { API_URL } from "../Global";
import { Box } from "@mui/system";

const Form = () => {
    const [inputValue, setInputValue] = useState("");
    const [gender, setGender] = useState("");
    const [categorys, setCategorys] = useState(null);
    const [subCategorys, setSubCategorys] = useState(null);
    const [surgeryCode, setsurgeryCode] = useState(null);
    const [categoryCode, setcategoryCode] = useState("");
    const [preInvestigation, setpreInvestigation] = useState("");
    const [preInvestigationName, setpreInvestigationName] = useState("");
    const [postInvestigationName, setPostInvestigationName] = useState("");
    const [midInvestigationName, setMidInvestigationName] = useState("");
    const [icdCode, setIcdCode] = useState("");
    const [PCSCode, setPCSCode] = useState("");
    const [uniqueSurgeryCode, setUniqueSurgeryCode] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [subCategorysName, setSubCategorysName] = useState("");
    const [surgeryName, setSurgeryName] = useState("");
    const [surgeryData, setSurgeryData] = useState("");
    const [preAuthDocuments, setPreAuthDocuments] = useState("");
    const [claimDocument, setClaimDocument] = useState("");

    const handleChange = (event) => {
        setGender(event.target.value);
    };

    const formData = `============================
Patient Name :- ${inputValue.firstName} ${inputValue.middleName} ${inputValue.lastName}
Gender :- ${gender}
Age :- ${inputValue.Age}

Ration Card Number:- ${inputValue.RationCardNumber}
Patient District:- ${inputValue.PatientDistrict}
Patient District:- ${inputValue.patientTaluka}

Surgery Name :- ${surgeryName}
Surgery Code :- ${uniqueSurgeryCode}
Pre auth documents:- ${preAuthDocuments}
Claim documents:- ${claimDocument}

Doctor Name :- ${inputValue.DoctorName}
Phone No.:- ${inputValue.phoneNo}
Designation :- ${inputValue.Designation}

Note :- ${inputValue.note}
============================
`;

    // api calls

    // fetching fetchCategorysList data
    // const fetchCategorysList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct  categoryCode,  categoryName as label  FROM procedureList`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             setCategorys(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchSubCategorysList data

    // const fetchSubCategorysList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     console.log("dd" + categoryCode);

    //     const query = `SELECT distinct subCategoryName as label  FROM procedureList WHERE categoryName="${categoryName}"`;
    //     console.log(query);
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             console.log(res.data);
    //             setSubCategorys(res.data);
    //             // fetchSubCategorysList();
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchsurgeryNameList data

    const fetchsurgeryNameList = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `SELECT concat(surgeryname , " " , surgeryCode) as label, surgeryCode FROM procedureList`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                setsurgeryCode(res.data);
            })
            .catch((err) => {
                console.log("categorys fetch error: ", err);
            });
    };

    const fetchSurgeryData = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `SELECT * FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                res.data.map((data) => {
                    console.log(data);
                    setPreAuthDocuments(data.preAuthDocuments);
                    setClaimDocument(data.claimDocuments);
                });
            })
            .catch((err) => {
                console.log("categorys fetch error: ", err);
            });
    };

    // fetching fetchpreInvestigationList data

    // const fetchpreInvestigationList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct preInvestigation as label  FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             res.data.map((data) => {
    //                 setpreInvestigation(data.label);
    //             });
    //             // setpreInvestigation(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchPostInvestigationList data

    // const fetchPostInvestigationList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct postInvestigation as label  FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             res.data.map((data) => {
    //                 setPostInvestigationName(data.label);
    //             });
    //             // setpreInvestigation(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchPostInvestigationList data

    // const fetchMidInvestigationList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct midInvestigation as label  FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             res.data.map((data) => {
    //                 setMidInvestigationName(data.label);
    //             });
    //             // setpreInvestigation(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchIcdCodeList data

    // const fetchIcdCodeList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct IcdCode as label  FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             res.data.map((data) => {
    //                 setIcdCode(data.label);
    //             });
    //             // setpreInvestigation(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };
    // fetching fetchIcdCodeList data

    // const fetchPCSCodeList = () => {
    //     let url = API_URL;
    //     // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    //     const query = `SELECT distinct PCSCode as label  FROM procedureList WHERE surgeryCode="${uniqueSurgeryCode}"`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             res.data.map((data) => {
    //                 setPCSCode(data.label);
    //             });
    //             // setpreInvestigation(res.data);
    //         })
    //         .catch((err) => {
    //             console.log("categorys fetch error: ", err);
    //         });
    // };

    useEffect(() => {
        fetchsurgeryNameList();
        fetchSurgeryData();
    }, [uniqueSurgeryCode]);
    return (
        <>
            <div className="container-lg my_css">
                <div>
                    <div className="grid grid-cols-6 gap-3">
                        <div className="col-start-3 col-end-6">
                            <h1 className="m-3 my_h1">
                                MAHATMA JYOTIRAO PHULE JAN AROGYA YOJANA
                            </h1>
                            <h5 className="m-3">
                                Free Quality Critical Care For low Income
                                Families
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <form className="bg">
                        <div className="row md-row lg-row m-3">
                            <h5>Patient details </h5>
                            <div className="col-sm-12 col-md-3">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            firstName: e.target.value,
                                        })
                                    }
                                    placeholder="first name"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label>Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            middleName: e.target.value,
                                        })
                                    }
                                    placeholder="middle name"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            lastName: e.target.value,
                                        })
                                    }
                                    placeholder="last name"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 ">
                                <InputLabel id="demo-simple-select-label">
                                    gender
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={gender}
                                    style={{ width: "150px" }}
                                    label="gender"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="row m-3">
                            <div className="col-sm-12 col-md-3 ">
                                <label>Age</label>
                                <input
                                    type="number"
                                    //  value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            Age: e.target.value,
                                        })
                                    }
                                    className="form-control"
                                    placeholder="Age"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label>Ration Card Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            RationCardNumber: e.target.value,
                                        })
                                    }
                                    placeholder="Ration Card Number"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 ">
                                <label>Patient District</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            PatientDistrict: e.target.value,
                                        })
                                    }
                                    placeholder="Patient District"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 ">
                                <label>Patient Taluka</label>
                                <input
                                    type=""
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            patientTaluka: e.target.value,
                                        })
                                    }
                                    placeholder="Patient Taluka"
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row m-3">
                            {/* product list */}
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} mt={2}>
                                    {/* <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            className="inputFieldp      "
                                            margin="dense"
                                            options={
                                                categorys != null
                                                    ? categorys
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="party name"
                                                    label="category Name"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                if (value != null) {
                                                    setCategoryName(
                                                        value.label
                                                    );

                                                    setcategoryCode(
                                                        value.categoryCode
                                                    );
                                                    // setcategoryCode(value.label);
                                                    // updateCurrentPrice("product", value.price);
                                                }
                                            }}
                                        />
                                    </Grid> */}

                                    {/* <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            className="inputFieldp      "
                                            margin="dense"
                                            options={
                                                subCategorys != null
                                                    ? subCategorys
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="party name"
                                                    label="Sub Category Name"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                if (value != null) {
                                                    setSubCategorysName(
                                                        value.label
                                                    );
                                                    //   setCurrentProduct(value.label);
                                                    //   updateCurrentPrice("product", value.price);
                                                }
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            className="inputFieldp  "
                                            options={
                                                surgeryCode != null
                                                    ? surgeryCode
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="party name"
                                                    label="surgery Name"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                if (value != null) {
                                                    setUniqueSurgeryCode(
                                                        value.surgeryCode
                                                    );
                                                    setSurgeryName(value.label);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="uniqueSurgeryCode"
                                            size="small"
                                            label="Unique Surgery Code"
                                            className="ICDCode  "
                                            name="uniqueSurgeryCode"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={uniqueSurgeryCode}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="preAuthDocument"
                                            size="small"
                                            label="Pre Auth Documents"
                                            className="ICDCode  "
                                            name="preAuthDocument"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={preAuthDocuments}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="claimDocument"
                                            size="small"
                                            label="Claim Documents"
                                            className="ICDCode  "
                                            name="claimDocument"
                                            type="text"
                                            variant="outlined"
                                            value={claimDocument}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>
                                    {/*  */}
                                    {/* <Grid item xs={12} md={3}>
                                        <TextField
                                            id="price"
                                            size="small"
                                            label="preInvestigation"
                                            className="preInvestigation  "
                                            name="Price"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={preInvestigation}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                            // onChange={(event, value) => {
                                            //     if (value != null) {
                                            //       setpreInvestigationName(value.label)

                                            //       // setcategoryCode(value.categoryCode);
                                            //         // setcategoryCode(value.label);
                                            //         // updateCurrentPrice("product", value.price);

                                            //     }

                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="postInvestigation"
                                            size="small"
                                            label="postInvestigation"
                                            className="postInvestigation  "
                                            name="postInvestigation"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={postInvestigationName}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="midInvestigation"
                                            size="small"
                                            label="midInvestigation"
                                            className="midInvestigation  "
                                            name="midInvestigation"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={midInvestigationName}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="icdCode"
                                            size="small"
                                            label="ICDCode"
                                            className="ICDCode  "
                                            name="icdCode"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={icdCode}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="PCSCode"
                                            size="small"
                                            label="PCSCode"
                                            className="PCSCode  "
                                            name="PCSCode"
                                            type="text"
                                            variant="outlined"
                                            // style={{ width: "150px" }}
                                            value={PCSCode}
                                            // onChange={(e) => {
                                            //     setCurrentPrice(e.target.value);
                                            // }}
                                        />
                                    </Grid> */}
                                </Grid>
                            </Box>
                            {/* product list */}
                        </div>
                        <hr />
                        <div className="row m-3">
                            <div className="col-sm-12 col-md-3 ">
                                <label>Doctor Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    //   value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            DoctorName: e.target.value,
                                        })
                                    }
                                    placeholder="Doctor Name"
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 ">
                                <label>Phone No.</label>
                                <input
                                    type="mobile"
                                    //  value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            phoneNo: e.target.value,
                                        })
                                    }
                                    className="form-control"
                                    placeholder="Phone No."
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 ">
                                <label>Designation</label>
                                <input
                                    type="text"
                                    // value={inputValue}
                                    onChange={(e) =>
                                        setInputValue({
                                            ...inputValue,
                                            Designation: e.target.value,
                                        })
                                    }
                                    className="form-control"
                                    placeholder="Designation"
                                />
                            </div>
                        </div>
                        <hr />
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1 mb-3">
                                Note:
                            </label>
                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={(e) => {
                                    setInputValue({
                                        ...inputValue,
                                        note: e.target.value,
                                    });
                                }}
                            ></textarea>
                        </div>
                        <hr />
                        <div className="my_btn">
                            <CopyToClipboard text={formData}>
                                <button
                                    type="button"
                                    className={
                                        "btn btn-outline-success mb-5 " +
                                        (inputValue.firstName != null &&
                                        inputValue.middleName != null &&
                                        inputValue.lastName != null
                                            ? ""
                                            : "disabled")
                                    }
                                >
                                    COPY DATA
                                </button>
                            </CopyToClipboard>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Form;
